<template>
    <div>
        <div class="content">
            <div class="content-title">{{parkingName}}</div>
            <div class="content-info">
                <div class="content-info-left">
                    <div class="content-info-left-title">
                        <span style="font-weight: bold;" v-if="couponType == 1">¥</span>
                        <span class="content-info-left-num"
                              v-if="couponType == 1">{{preferential?preferential:'0'}}</span>
                        <span class="content-info-left-num" v-else-if="couponType == 2">{{preferential?preferential*10:'0'}}</span>
                        <span class="content-info-left-num" v-if="couponType == 3">全免</span>
                        <span style="font-weight: bold;" v-if="couponType == 2">折</span>
                    </div>
                    <div class="content-info-left-time">
                        <span>有效期至 {{time}}</span>
                    </div>
                </div>
                <div class="content-info-right">
                    <span>{{merchantName}}</span>
                    <span>{{couponName}}
                        <!--                        {{couponType == 1 ? '现金券' :-->
                        <!--                        couponType == 2 ? '折扣券' :-->
                        <!--                        couponType == 3 ? '全免券' : '券' }}-->
                    </span>
                </div>
            </div>
        </div>
        <div class="content-img">
            <div class="content-img-info">
                <div>领券密码</div>
                <input v-model="encryptionCode" type="text" placeholder="询商户获取"/>
            </div>
        </div>
        <div class="content-center">
            <div class="content-center-switch">
                <div :class="checked== true? 'switch-color' : ''">有牌车</div>
                <van-switch v-model="checked" active-color="#9269ea" inactive-color="#d9c7f9" size="20px"/>
                <div :class="checked== false? 'switch-color' : ''">无牌车</div>
            </div>
            <keyboard v-show="checked== false" ref="child" @confirmBtnFn="confirmBtnFn" :licensePlate="carNumber"/>
        </div>
        <div class="content-bottom" @click="getCollectCoupons">确认领取</div>
        <!--        <div class="content-bottom" v-if="subscribe == 1" @click="getCollectCoupons">确认领取</div>-->
        <!--        <div class="content-bottom" v-else-if="subscribe == 0" @click="handleDialog">确认领取</div>-->
        <van-divider :style="{ color: '#646464', borderColor: '#646464', padding: '0 20px' }"/>
        <div class="content-divider">
            使用指南：在出场前，将优惠券与在场车牌号绑定，减免停车费用!
        </div>
    </div>
</template>

<script>
    import {Button, Dialog, RadioGroup, Radio, Divider, Switch} from 'vant';
    import {collectCoupons, couponInfo, getIsSubscribe} from "../api/interface";
    import Toast from "vant/lib/toast";
    import Keyboard from '../components/Numkey'

    export default {
        name: "collect",
        components: {
            [Button.name]: Button,
            [Radio.name]: Radio,
            [RadioGroup.name]: RadioGroup,
            [Divider.name]: Divider,
            [Switch.name]: Switch,
            Keyboard
        },
        data() {
            return {
                couponName: "",
                time: "",
                preferential: "",
                encryptionCode: "",
                couponType: "",
                carNumber: "",
                collectMethod: "",
                merchantName: "",
                parkingName: "",
                id: "",
                subscribe: "",
                checked: false,
            }
        },
        created() {
            this.couponInfo();
        },
        mounted() {
            this.getUserInfo();
        },
        methods: {
            confirmBtnFn(val) {
                var result = val.replace(/(^\s+)|(\s+$)/g, "");
                this.carNumber = result.replace(/\s/g, "");
            },
            getUserInfo() {
                getIsSubscribe(sessionStorage.getItem('openId')).then(res => {
                    this.subscribe = res.data.data;
                })
            },
            couponInfo() {
                couponInfo(this.$route.query.couponId).then((res) => {
                    if (res.data.code == 'SUCCESS') {
                        this.couponName = res.data.data.couponName;
                        this.couponType = res.data.data.couponType;
                        this.preferential = res.data.data.preferential;
                        this.merchantName = res.data.data.merchantName;
                        this.parkingName = res.data.data.parkingName;
                        if (res.data.data.endEffectiveTime != null) {
                            this.time = res.data.data.endEffectiveTime;
                        }
                        // if (res.data.data.startEffectiveTime != null || res.data.data.endEffectiveTime != null) {
                        //     this.time = res.data.data.startEffectiveTime + " / " + res.data.data.endEffectiveTime;
                        // }
                    }
                })
            },
            getCollectCoupons() {
                this.$refs.child.confirmBtnFn();
                if (this.checked === false) {
                    if (this.carNumber === "") {
                        Toast("请输入车牌号");
                        return;
                    }
                }
                let param = {
                    collectMethod: this.checked == false ? '1' : this.checked == true ? '2' : '',
                    encryptionCode: this.encryptionCode,
                    carNumber: this.carNumber,
                    id: this.$route.query.couponId
                }
                collectCoupons(param).then((res) => {
                    if (res.data.code == 'SUCCESS') {
                        Toast('领劵成功');
                        this.encryptionCode = "";
                        this.carNumber = "";
                    }
                })
            },
            handleDialog() {
                Dialog.confirm({
                    title: '温馨提示',
                    message: '请关注公众号“链路云停”扫码领取优惠券！',
                    confirmButtonColor: '#DC5040'
                }).then(() => {
                    // on confirm
                    let url = "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzkxMDI4MTcyNQ==#wechat_redirect";
                    window.location.href = url;
                }).catch(() => {
                    // on cancel
                });
            }
        },
        beforeRouteEnter(to, from, next) {
            // 添加背景色
            document.querySelector('body').setAttribute('style', 'background-color:#fff')
            next()
        },
        beforeRouteLeave(to, from, next) {
            // 去除背景色
            document.querySelector('body').setAttribute('style', '')
            next()
        }
    }
</script>

<style lang="less" scoped>
    .content {
        display: flex;
        flex-direction: column;
        font-size: 16px;
        margin: 30px 20px 0 20px;
        background: url("../assets/img/copon_04.png") no-repeat;
        background-size: contain;
        background-size: 100%;
    }

    .content-title {
        font-size: 14px;
        margin: 10px 25px 20px 25px;
        display: flex;
        justify-content: left;
        color: #ffffff;
        font-family: "微软雅黑 Light";
    }

    .content-info {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        padding-bottom: 15px;
    }

    .content-info-left {
        display: flex;
        flex-direction: column;
        line-height: 30px;
        text-align: center;
        width: 65%;
    }

    .content-info-left-title {
        color: #fafafa;
    }

    .content-info-left-num {
        font-size: 40px;
        font-weight: bold;
        font-family: "微软雅黑 Light";
    }

    .content-info-left-time {
        /*background-color: #FD4A1B;*/
        border-radius: 10px;
    }

    .content-info-left-time span {
        color: #ffffff;
        font-size: 12px;
        font-family: "微软雅黑 Light";
    }

    .content-info-right {
        display: flex;
        flex-direction: column;
        line-height: 25px;
        text-align: center;
        width: 33%;
    }

    .content-info-right span {
        color: #ffffff;
        font-size: 13px;
        font-family: "微软雅黑 Light";
    }

    .content-img {
        margin: 2px 20px 0 20px;
        justify-content: space-around;
        background: url("../assets/img/copon_05.png") no-repeat;
        background-size: contain;
        background-size: 100%;
    }

    .content-img-info {
        display: flex;
        flex-direction: row;
        font-size: 16px;
        color: #F9F9F9;
        width: 100%;
        font-family: "微软雅黑 Light";
        line-height: 37px;
    }

    .content-img-info div {
        font-size: 14px;
        padding: 0 20px;
        width: 30%;
        text-align: center;
    }

    .content-img-info input {
        height: 30px;
        border: none;
        background: #c1c1c1;
        width: 50%;
    }

    .content-center {
        display: flex;
        flex-direction: column;
        margin: 30px 0 50px 0;
        height: 150px;
    }

    .content-center-switch {
        font-size: 16px;
        padding-right: 20px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .switch-color {
        color: #8a8a8a;
    }

    .content-bottom {
        color: #F9F9F9;
        font-size: 16px;
        padding: 13px 0;
        margin: 40px 20px;
        background: url("../assets/img/copon_2.png") no-repeat;
        background-size: contain;
        background-size: 100%;
        font-family: "微软雅黑 Light";
        text-align: center;
    }

    .content-divider {
        font-size: 11px;
        font-family: "微软雅黑";
        margin: 0 20px;
        color: #222222;
    }

    /deep/ .van-radio__icon .van-icon {
        border: 3px solid #fff;
        background-color: #fff;
    }

    /deep/ .van-radio__label {
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        line-height: 40px;
    }

    /deep/ .van-radio__icon--checked .van-icon {
        color: #FD4A1B;
        background-color: #FD4A1B;
        border-color: #fff;
    }

    input::placeholder {
        color: #d3d3d3;
        text-align: center;
        font-size: 14px;
        /*padding-left: 5px;*/
    }
</style>
